module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"martin.is"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Martineering One Page","short_name":"Martineering","start_url":"/","icon":"src/images/m-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"951c9a37346f2ea518ed0a674b6121ae"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
